<template>
  <vp-base-layout
    page-title="IMEI 입력"
    page-default-back-link="/">
    <ion-list lines="full">
      <ion-list-header>
        <ion-label>휴대폰의 IMEI를 입력해주시고 <br /> IMEI가 표시된 화면을 촬영해주세요</ion-label>
      </ion-list-header>
      
      <ion-item>
        <ion-label
          fixed
          class="fixedLabel">
          사진 촬영
        </ion-label>
        <!-- <ion-button @click="handlePhoto1()">
          촬영하기
        </ion-button> -->
        
        <ion-button @click="handlePhoto">
          <ion-icon :icon="barcodeOutline" />
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-label>IMEI 입력</ion-label>
        <ion-input
          placeholder="ex) 1234567890, ‘-’ 없이 숫자만"
          v-model="imeiData.imei"
          ref="imeiEl"
          @ion-input="onInput($event)"
          maxlength="15" />
      </ion-item>
      <!-- <ion-item v-if="photo1">
        <ion-img
          :src="photo1"
          @ion-error="photo1 = '/assets/images/common/no-image.png'"
          @click="handlePhoto1()" />
        <ion-button
          class="clear"
          @click="removePhoto1()">
          <ion-icon :icon="trash" />
        </ion-button>
      </ion-item> -->
      <ion-item>
        <div
          v-show="barcodeReaderShow"
          ref="barcodeScanner">
        </div>
      </ion-item>
    </ion-list>
    <ion-list lines="none">
      <ion-item>
        <ion-text>
          전화 App에서 *#06#을 눌러주세요.<br />
          표시되는 IMEI를 입력하고 해당 화면을 사진을 찍어<br />
          입력해주세요.
          <p></p>
          전화 App에서 IMEI가 표시되지 않는다면 설정 <br />
          App을 열어 휴대폰정보 화면을 사용해주세요.
        </ion-text>
      </ion-item>
    </ion-list>
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="imeiInputStore">
      IMEI 입력하기
    </vp-button>
  </vp-base-layout>
</template>
  
<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonInput,
  IonLabel,
  IonItem,
  IonListHeader,
  IonList,
  IonButton,
  IonImg,
  IonIcon,
  toastController
} from '@ionic/vue'
import { cameraOutline, barcodeOutline, trash } from 'ionicons/icons'
// import { Camera, CameraResultType } from '@capacitor/camera'
import { useStore } from 'vuex'
import { ref, defineExpose, defineComponent, onMounted } from 'vue'
import { useIonRouter } from '@ionic/vue'
import Quagga from 'quagga'
import isEmpty from 'lodash/isEmpty'

//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const barcodeScanner = ref()

const store = useStore()
const ionRouter = useIonRouter()

const fixed = ref(true)
const barcodeReaderShow = ref(false)
const imeiData = ref({})

const onDecode = (text) => {
  imeiData.value.imei = text
  barcodeReaderShow.value = false
}

const param = {
	action:'qrcamera',
	callback:'get_code' //읽혀진 코드값 전달받을 javascript함수명(웹소스에 정의필요)
}
// const get_code = (val) => {
//   console.log('test')
//   alert(val)
//   imeiData.value.imei = val
// }

const onLoaded = () => {
  console.log('load')
 
}

onMounted(() => {
  console.log('Component mounted!')
  window.get_code = function(message) {
    console.log('Global function called with message:', message)
    imeiData.value.imei = message
  }

})

const handleCheck = async() => {
  const toast = await toastController.create({
    message: 'IMEI정보를 입력해 주세요.',
    buttons: ['확인']
  })
  await toast.present()
}

const handleImeiCheck = async() => {
  const toast = await toastController.create({
    message: 'IMEI번호가 유효하지 않습니다.',
    buttons: ['확인']
  })
  await toast.present()
}

/* let photo1 = ref(undefined)
const handlePhoto1 = async () => {
  const image = await Camera.getPhoto({
    quality: 10,
    allowEditing: true,
    resultType: CameraResultType.DataUrl
  })

  photo1.value = image.dataUrl
  imeiData.value.file1_id = image.dataUrl
}

const removePhoto1 = async () => {
  photo1.value = undefined
  imeiData.value.file1_id = undefined
} */

const imeiEl = ref()
const onInput = (ev) => {
  const value = ev.target?.value
  const regex = value.replace(/[^0-9]+/g, '')
  const inputCmp = imeiEl.value
  if (inputCmp !== undefined) {
    inputCmp.$el.value = regex
  }
}

const handlePhoto = async () => {

  if(navigator.userAgent.toLowerCase().indexOf('androidapp') != -1){
    //안드로이드 앱
    window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(param))


  }else if (navigator.userAgent.toLowerCase().indexOf('iosapp') != -1){
    //ios 앱
    window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(param))

  }else {
      Quagga.init({
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: barcodeScanner.value,
          locate: true,
          constraints: { 
            width: { 
              min: 640
            }, 
            height: {
              min: 480
            }, 
            facingMode: 'environment'
          }
        },
        decoder: {
          readers: ['code_128_reader']
        }
      }, function(err) {
          if (err) {
              console.error(err)
              return
          }
          Quagga.start()
          barcodeReaderShow.value = true
      })
      
      Quagga.onDetected((data) => {
        if (data?.codeResult?.code) {
          imeiData.value.imei = data?.codeResult?.code
          Quagga.stop()
          barcodeReaderShow.value = false
        }
      })

  }


  



}

// IMEI 번호 유효성 체크
/*
const imeiValidationCheck = () => {
  let str = ''
  let imeiVal = imeiData.value.imei
  let sum = 0
  let len = imeiData.value.imei.length-1
  let imeiLen = imeiData.value.imei.length
  let sub = 0
  if(imeiLen === 15 || imeiLen === 17){
    for(let i = 0; i < len; i+=1){
      if(i % 2 !== 0){
        sub = (parseInt(imeiVal[i]) * 2).toString()
        let sub1 = 0
        if(!isEmpty(sub[1])){
          sub1 = parseInt(sub[1])
        }
        sum += (sum.toString().length === 2) ? (parseInt(sub[0]) + sub1) : parseInt(sub[0])
      } else {
        sum += parseInt(imeiData.value.imei[i])
      }
    }
    if(parseInt(imeiVal[len]) ===  (((parseInt(sum / 10) + 1) * 10) - sum)){
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
*/
 
const imeiValidationCheck = () => {
  let number = imeiData.value.imei
  let result = false
  let numbers = []
  let length, newNumber, sum, nearestMultipleOfTen, difference

  length = newNumber = sum = nearestMultipleOfTen = difference = 0

  while (number) {
    numbers.push(number % 10)
    number = Math.floor(number / 10)
  }

  numbers.reverse()

  length = numbers.length

  if (length !== 15)
    return result

  for (let i = 0; i < 15; i++) {
    if (i % 2 != 0) {
        switch (numbers[i]) {
          case 0:
            // 0 * 2 = 0
            newNumber = 0
            break
          case 1:
            // 1 * 2 = 2
            newNumber = 2
            break
          case 2:
            // 2 * 2 = 4
            newNumber = 4
            break
          case 3:
            // 3 * 2 = 6
            newNumber = 6
            break
          case 4:
            // 4 * 2 = 8
            newNumber = 8
            break
          case 5:
            // 5 * 2 = 10
            // 1 + 0 = 1
            newNumber = 1
            break
          case 6:
            // 6 * 2 = 12
            // 1 + 2 = 3
            newNumber = 3
            break
          case 7:
            // 7 * 2 = 14
            // 1 + 4 = 5
            newNumber = 5
            break
          case 8:
            // 8 * 2 = 16
            // 1 + 6 = 7
            newNumber = 7
            break
          case 9:
            // 9 * 2 = 18
            // 1 + 8 = 9
            newNumber = 9
            break
        }
        numbers.splice(i, 1, newNumber)
    }
  }

  for (let i = 0; i < 14; i++) {
    sum += numbers[i]
  }

  nearestMultipleOfTen = Math.ceil(sum / 10) * 10
  difference = nearestMultipleOfTen - sum

  if (difference == numbers[14])
    result = true

  return result
}


//IMEI 입력하기
const imeiInputStore = () => {
  if(!imeiData.value.imei){
    handleCheck()
    return
  }

  if(!imeiValidationCheck()) { 
    handleImeiCheck()
    return  
  }

  store.dispatch('Receipt/setImei', imeiData.value)
  ionRouter.push({name: 'deviceInfo'})
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
</script>

